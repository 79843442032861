<template>
    <el-scrollbar class="education-wrapper" :native="false">
        <div class="steps-wrapper">
            <div class="step-item" :class="{current: [1, 2, 3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">创建商品信息</div>
            </div>
            <div class="step-item" :class="{current: [2, 3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">添加商品属性</div>
            </div>
            <div class="step-item" :class="{current: [3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">3</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>
        <div class="create-content" v-if="stepActive === 1">
            <el-form :model="stepOne" :rules="stepOneRules" ref="stepOne" label-width="120px">
                <el-form-item label="创建名称" prop="name">
                    <el-input v-model="stepOne.name" placeholder="4-30个字" clearable style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="行业分类" prop="industry_id">
                    <el-select v-model="stepOne.industry_id" placeholder="请选择行业分类" clearable style="width: 500px">
                        <el-option v-for="cateItem in categoryList" :key="cateItem.id" :label="cateItem.name" :value="cateItem.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="bz">
                    <el-input v-model="stepOne.bz" placeholder="2-255个字" style="width: 500px" maxlength="255" show-word-limit></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="create-content" v-if="stepActive === 2">
            <div class="stepTwo-head">
                <div class="left">
                    <el-button class="btn-red-line-all" size="small" @click="delChooseGoods">删除</el-button>
                    <el-button size="small" class="btn-blue-line-all" @click="addBtn(null)">添加新商品信息</el-button>
                </div>
                <div class="right">
                    <span class="text">使用官方数据</span>
                    <el-select v-model="templateId" placeholder="自定义" clearable style="width: 150px" @change="chooseOfficialData" @clear="clearSingle">
                        <el-option v-for="goodsItem in goodsList" :key="goodsItem.id" :label="goodsItem.name" :value="goodsItem.id"></el-option>
                    </el-select>
                </div>
            </div>
<!--            @selection-change="selectChange" :row-class-name="tableRowClassName"-->
            <el-table :data="stepOne.data" border style="width: 100%; margin-top: 10px;" size="medium" default-expand-all class="attr-table"
                      @selection-change="selectChange" :row-class-name="tableRowClassName"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                      :cell-style="{fontSize: '14px',color: '#343441'}">
                <el-table-column type="selection" align="center" width="55"></el-table-column>
                <el-table-column label="商品属性名称">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name" placeholder="请输入商品属性名称" style="width: 80%"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="商品属性"></el-table-column>
                <el-table-column label="操作" class-name="row-expand-last" width="200" align="right">
                    <template slot-scope="scope">
                        <el-link :underline="false" class="link-blue" @click="addBtn(scope.row)">添加属性</el-link>
                        <el-link :underline="false" type="danger"  @click="delBtn(scope.row, scope.$index, 0)">删除</el-link>
                    </template>
                </el-table-column>
                <el-table-column type="expand" class-name="row-expand">
                    <template slot-scope="props" v-if="props.row.data.length > 0">
                        <el-table :data="props.row.data" style="width: 100%;" size="medium" :show-header="false"
                                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                                  :cell-style="{fontSize: '14px',color: '#343441'}">
                            <el-table-column width="55" class-name="row-one"></el-table-column>
                            <el-table-column></el-table-column>
                            <el-table-column label="商品属性">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.attr" placeholder="请输入商品属性" style="width: 80%"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" class-name="row-expand-last" width="200" align="right">
                                <template slot-scope="scope">
                                    <el-link :underline="false" type="danger" @click="delBtn(scope.row, scope.$index, props)">删除</el-link>
                                </template>
                            </el-table-column>
                            <el-table-column width="47" class-name="row-last"></el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="create-content" v-if="stepActive === 3">
            <div class="success-content">
                <i class="iconfont">&#xe730;</i>
                <p class="text">创建成功！</p>
            </div>
        </div>
        <div class="bottom-btn">
            <el-button @click="prevBtn(stepActive)" class="btn-blue-line-all" v-if="stepActive === 1 || stepActive === 2">上一步</el-button>
            <el-button @click="prevBtn(stepActive)" class="btn-blue-line-all" v-if="stepActive === 3">返回</el-button>
            <el-button class="btn-blue" @click="nextBtn(stepActive, 'stepOne')"  v-if="stepActive === 1">下一步</el-button>
            <el-button class="btn-blue" @click="nextBtn(stepActive)"  v-if="stepActive === 2">下一步</el-button>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        data() {
            return {
                goodInfoId: Number(this.$route.query.id) || '',
                stepActive: 1,
                stepOne: {
                    name: '',
                    industry_id: '',
                    bz: '',
                    data: [], // 添加的商品列表
                },
                stepOneRules: {
                    name: [
                        { required: true, message: '请输入商品信息名称', trigger: 'blur' },
                        { min: 4, max: 30, message: '长度在 4 到 30 个字', trigger: 'blur' }
                    ],
                    industry_id: [
                        { required: true, message: '请选择行业分类', trigger: 'change' }
                    ],
                },
                categoryList: [], // 行业分类列表
                templateId: null, // 官方数据id
                goodsList: [], // 官方数据列表
                chooseGoodsList: [], // 选择要删除的商品属性
            }
        },
        mounted() {
            this.getCategoryList()
            this.getGoodsList()
            this.getOneGoodsInfo()
        },
        methods: {
            // 获取单条商品信息
            getOneGoodsInfo() {
                if (this.goodInfoId) {
                    this.$http.axiosGetBy(this.$api.itemInfo, {id: this.goodInfoId}, (res) => {
                        if (res.code === 200) {
                            res.data.industry_id = res.data.industry_id === 0? null : res.data.industry_id;
                            this.stepOne = res.data
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }
            },
            // 获取官方数据列表
            getGoodsList() {
                this.$http.axiosGet(this.$api.itemInfo, (res) => {
                    if (res.code === 200) {
                        this.goodsList = res.data.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 选择官方数据
            chooseOfficialData(val) {
                if (val) {
                    this.$http.axiosGetBy(this.$api.itemInfo, {id: val}, (res) => {
                        if (res.code === 200) {
                            this.stepOne.data = res.data.data
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }
            },
            // 清除选择官方数据
            clearSingle() {
                this.stepOne.data = []
            },
            // 获取行业分类列表
            getCategoryList() {
                this.$http.axiosGet(this.$api.categoryList, (res) => {
                    if (res.code === 200) {
                        this.categoryList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 上一步按钮
            prevBtn(index) {
                if (index === 1) {
                    this.$router.go(-1)
                } else if (index === 2) {
                    this.stepActive = 1
                } else if (index === 3) {
                    this.$router.push('/admin/operatedata/goodsattr')
                }
            },
            // 下一步按钮
            nextBtn(index, formName) {
                // 第一步的下一步
                if (index === 1) {
                    // this.stepActive = 2
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let params = {
                                name: this.stepOne.name
                            }
                            if (this.goodInfoId) {
                                params.id = this.goodInfoId
                            }
                            this.$http.axiosPost(this.$api.itemInfo_name, params, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg)
                                    this.stepActive = 2
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                // 第二步的下一步
                } else if (index === 2) {
                    if (this.stepOne.data.length === 0) {
                        this.$message.warning('请添加商品信息')
                        return
                    }

                    for (let i = 0; i < this.stepOne.data.length; i++) {
                        let item = this.stepOne.data[i]
                        if (item.name === '') {
                            return this.$message.warning('请输入商品属性名称')
                        }
                        if (item.data.length === 0) {
                            return this.$message.warning('请给商品属性名称添加属性')
                        }
                        if (item.data.length > 0) {
                            for (let j = 0; j < item.data.length; j++) {
                                if (item.data[j].attr === '') {
                                    return this.$message.warning('请输入商品属性')
                                }
                            }
                        }
                    }

                    let formData = new FormData();
                    formData.append('name', this.stepOne.name);
                    formData.append('industry_id', this.stepOne.industry_id);
                    formData.append('bz', this.stepOne.bz);
                    formData.append('data', JSON.stringify(this.stepOne.data));
                    if (this.goodInfoId) {
                        formData.append('id', this.goodInfoId)
                    }
                    this.$http.axiosPost(this.$api.itemInfo, formData, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.stepActive = 3
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }
            },
            // 添加新商品信息
            addBtn(row = null) {
                if (row) {
                    let attrTmp = {
                        attr: '',
                    }
                    row.data.push(attrTmp)
                } else {
                    let attrTmp = {
                        name: '',
                        data: []
                    }
                    this.stepOne.data.push(attrTmp)
                }
            },
            // 删除行
            delBtn(row, index, props) {
                if (props) {
                    props.row.data.splice(index, 1)
                    this.$message.success('删除成功')
                } else {
                    this.stepOne.data.splice(index, 1)
                    this.$message.success('删除成功')
                }
            },
            // 表格多选,配合下面的方法删除多选
            selectChange(selection) {
                this.chooseGoodsList = selection
            },
            // 行class的回调函数，把index放到商品表格数据里面，删除的时候要用index
            tableRowClassName({row, rowIndex}) {
                row.index = rowIndex
            },
            // 删除多选商品
            delChooseGoods() {
                if (this.stepOne.data.length === 0) {
                    return this.$message.warning('没有可删除的商品信息')
                } else if (this.chooseGoodsList.length === 0) {
                    return this.$message.warning('请选择要删除的商品信息')
                } else {
                    this.$confirm('是否删除所选择的商品信息，删除完将无法恢复', '提示消息', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        confirmButtonClass: 'btn-red',
                        type: 'warning',
                    }).then(() => {
                        this.stepOne.data = this.stepOne.data.filter(item => {
                            let arrTmp = this.chooseGoodsList.map(selectItem => selectItem.index)
                            return arrTmp.includes(item.index) === false
                        })
                        this.$message.success('删除成功')
                    }).catch(() => {
                        this.$message.info('已取消删除')
                    });
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .education-wrapper {
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                padding: 30px;
            }
        }
    }
    .steps-wrapper {
        margin-top: 70px;
    }
    .create-content {
        margin: 60px 0;
    }
    .success-content {
        margin-top: 155px;
        text-align: center;
        .iconfont {
            color: #1FD18C;
            font-size: 52px;
        }
        .text {
            font-size: 24px;
            margin: 20px 0 0;
            font-weight: bold;
        }
    }
    .bottom-btn {
        text-align: center;
    }
    .stepTwo-head {
        display: flex;
        justify-content: space-between;
        .text {
            margin: 0 10px 0 20px;
        }
    }
    .goods-cover {
        width: 44px;
        height: 44px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid #eee;
        background: #fafafa;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .symbol-style {
        margin-left: 6px;
    }
    .wavy-line {
        line-height: 40px;
        margin: 0 7px 0 6px;
    }
    ::v-deep .el-input-number {
        .el-input__inner {
            text-align: left;
        }
    }

    ::v-deep .attr-table {
        .row-expand-last {
            border-right: 0;
        }
        .row-expand {
            .cell {
                display: none;
            }
        }
        .el-table__expanded-cell {
            border-bottom: 0;
            padding: 0;
            .el-table {
                .row-one {
                    border-right: 0;
                }
                .row-last {
                    border-right: 0;
                }
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
</style>